@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .radio {
    position: absolute;
    overflow: hidden;
    width: 1px;
    height: 1px;
    padding: 0;
    border: 0;
    margin: -0.0625rem;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
  }

  .mask {
    width: 1.5rem;
    height: 1.5rem;
    flex-shrink: 0;
    border: 0.09375rem solid #CACACA;
    /* border-color: rgb(165, 179, 208); */
    background-color: #fff;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 0.8125rem 0.6875rem;
    border-radius: 50%;
    cursor: pointer;
  }

  .mask-disabled {
    width: 1.5rem;
    height: 1.5rem;
    flex-shrink: 0;
    border: 0.09375rem solid #EAEAEA;
    border-color: #EAEAEA;
    background-color: #fff;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 0.8125rem 0.6875rem;
    border-radius: 50%;
    cursor: pointer;
  }

  .mask-un{
    width: 1.5rem;
    height: 1.5rem;
    flex-shrink: 0;
    border: 0.09375rem solid #a5b3d0;
    border-color: rgb(165, 179, 208);
    background-color: #fff;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 0.8125rem 0.6875rem;
    border-radius: 50%;
    cursor: pointer;
  }

  .radio:checked + .mask {
    background-color: rgb(0, 174, 199);
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEyLjQzNi45MzhMNC44ODQgOS4zNCAxLjU2IDYuODFsLS43NDYuOTE1IDQuMzE0IDMuMzM3IDguMjc5LTkuNDUyLS45Ny0uNjczeiIgZmlsbD0iI2ZmZiIvPjwvc3ZnPg==');
    border-color: rgb(0, 174, 199);
  }
}

@layer utilities {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    @apply appearance-none;
  }
}

.vto-canvas {
  transform: scale(-1, 1);
}
#__next {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

body {
  overflow-x: hidden;
  min-width: 320px;
  background-color: #fff;
  -webkit-tap-highlight-color: transparent;
  min-height: 100vh;
  touch-action: manipulation;
}

img {
  -webkit-user-drag: none;
}

@font-face {
  font-family: 'Graphik';
  src: url('https://cdn.dresden.vision/fonts/Graphik-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik';
  src: url('https://cdn.dresden.vision/fonts/Graphik-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik';
  src: url('https://cdn.dresden.vision/fonts/Graphik-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik';
  src: url('https://cdn.dresden.vision/fonts/Graphik-Semibold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
	font-family: 'Cooper';
	src: url("https://cdn.dresden.vision/fonts/CooperBlackStd.woff2") format('woff2');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

#nprogress .bar {
  background: #f17b2d !important;
  height: 6px !important;
}

#nprogress .peg {
  box-shadow: 0 0 10px #f17b2d, 0 0 5px #f17b2d !important;
}

.sr-only ~ .dot {
  transform: translateX(100%);
  background-color: #48bb78;
}

.react-datepicker__navigation--next {
  top: 9px !important;
}
.react-datepicker__navigation--previous {
  top: 9px !important;
}

.react-datepicker__header {
  background: #fcfcfc !important;
  border-color: #dadeda !important;
}

.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle::after {
  border-bottom-color: #fcfcfc !important;
}

.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle::before {
  border-bottom-color: #dadeda !important;
}

.react-datepicker {
  border-color: #dadeda !important;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background: transparent !important;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  background: transparent !important;
}

::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #b1b9c3;
  border: 1px none #ffffff;
  border-radius: 0.5rem;
}
::-webkit-scrollbar-thumb:hover {
  background: #949ba4;
}
::-webkit-scrollbar-thumb:active {
  background: #9e9e9e;
}
::-webkit-scrollbar-track {
  background: #ffffff;
  border: 1px none #ffffff;
  border-radius: 0.5rem;
}
::-webkit-scrollbar-track:hover {
  background: #ffffff;
}
::-webkit-scrollbar-track:active {
  background: #ffffff;
}
::-webkit-scrollbar-corner {
  background: transparent;
}
.spinner {
  width: 70px;
  text-align: center;
}

.spinner > div {
  width: 10px;
  height: 10px;
  background-color: #212721;
  margin: 0 2px;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 500000s ease-in-out 0s;
}

.AppWrapper {
  width: 500px;
  position: relative;
}

@keyframes fade {
  from {
    opacity: 0;
    transform: scale3D(0.95, 0.95, 0.95);
  }
  to {
    opacity: 1;
    transform: scale3D(1, 1, 1);
  }
}

.Form {
  animation: fade 200ms ease-out;
}

.FormGroup {
  padding: 0;
  border-style: none;
  background-color: #f3f4f3;
  will-change: opacity, transform;
  border-radius: 4px;
}

.StripeElement--webkit-autofill {
  background: transparent !important;
}

.StripeElement {
  width: 100%;
  padding: 11px 15px 11px 0;
}

.swiper-pagination-bullet:not([data-ignore-global-swiper-bullet-style]) {
  width: 4px !important;
  height: 4px !important;
}
.swiper-pagination-bullet-active:not([data-ignore-global-swiper-bullet-style]) {
  background: #565456 !important;
}

.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0px 1.5px !important;
}

.swiper-horizontal
  > .swiper-pagination-bullets:not([data-ignore-global-swiper-pagination-style]),
.swiper-pagination-bullets.swiper-pagination-horizontal:not([data-ignore-global-swiper-pagination-style]),
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 0 !important;
}

.hr {
  width: 2px;
  height: 54px;
  position: absolute;
  background: #dedede;
  opacity: 0.5;
  -moz-transform: rotate(315deg);
  -o-transform: rotate(315deg);
  -webkit-transform: rotate(315deg);
  -ms-transform: rotate(315deg);
  transform: rotate(315deg);
}

.hr-for-frame {
  width: 2px;
  height: 130px;
  position: absolute;
  background: #dedede;
  opacity: 0.5;
  -moz-transform: rotate(63deg);
  -o-transform: rotate(63deg);
  -webkit-transform: rotate(63deg);
  -ms-transform: rotate(63deg);
  transform: rotate(63deg);
}
@media only screen and (max-width: 600px) {
  /* .hr {
    width: 2px;
    height: 54px !important;
    position: absolute;
    background: #dedede;
    opacity: 0.5;
    transform: rotate(315deg);
    -moz-transform: rotate(315deg);
    -o-transform: rotate(315deg);
    -webkit-transform: rotate(315deg);
    -ms-transform: rotate(315deg);
  } */

  .hr-for-frame {
    width: 2px;
    height: 98px !important;
    position: absolute;
    background: #dedede;
    opacity: 0.5;
    -moz-transform: rotate(59deg);
    -o-transform: rotate(59deg);
    -webkit-transform: rotate(59deg);
    -ms-transform: rotate(59deg);
    transform: rotate(59deg);
  }
}

@media only screen and (min-width: 1024px) {
  .overflow-hidden-body {
    overflow: hidden;
    margin-right: 6px;
  }
  .overflow-hidden-nav {
    width: calc(100% - 6px);
  }
	.swiper-pagination {
		display: none;	
	}
}

.custom-tooltip {
  opacity: 1 !important;
  pointer-events: auto !important;
  cursor: auto !important;
}

.aligncenter {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

html[data-fullscreen-overlay-visible='true'],
html[data-fullscreen-overlay-visible='true'] > body {
  overflow: hidden;
  position: relative;
}

/* Perfect Scrollbar */
.ps__rail-y {
  background-color: transparent !important;
}

.st-pricing-grid-wrapper {
  @apply mt-[10px] grid grid-cols-2 gap-[20px] rounded-[4px] bg-brand-grey225 p-[20px] lgx:!mx-auto lgx:max-w-[300px] lgx:grid-cols-1 smx:max-w-none;
}

.st-pricing-grid-2-column {
  @apply col-span-2 lgx:col-span-1;
}

.st-pricing-grid-title {
  @apply text-[18px] font-medium text-brand-orange;
}

.st-pricing-grid-list {
  @apply max-w-[238px] lgx:max-w-none;
}

.st-pricing-grid-list-item {
  @apply mt-[10px] flex justify-between;
}

.st-dark-button {
  @apply inline-block h-[48px] w-full rounded-[6px] bg-brand-black100 sm:px-[60px] text-center font-medium leading-[44px] text-white transition-colors duration-200 hover:text-brand-orange focus:text-brand-grey450 sm:w-auto;
}


.card-hover::before {
    content: "";
    opacity: 0;
    position: absolute;
    border-radius: 9px;
    top: 0;
    right: -5px;
    bottom: 0;
    left: -5px;
    z-index: 1;
    transform: scale(0.9);
    transition: transform 450ms cubic-bezier(0.345, 0.115, 0.135, 1.42),opacity 350ms ease-out,border-color 450ms ease-out;
    @apply shadow-card
}

.card-hover:hover::before {
  @apply lg:opacity-100;
  transform: scale(1);
}

.seo-text {
  @apply text-brand-grey300 text-[14px];
}
.seo-text p {
  @apply mb-[10px];
}
.seo-text a, .seo-text strong {
  @apply text-brand-black100 font-medium;
}
.seo-title-1 {
  @apply text-[24px] font-bold block text-brand-black100 my-[20px];
}
.seo-title-2 {
  @apply text-[18px] font-bold text-brand-black100 my-[10px];
}
.seo-title-3 {
  @apply text-[16px] font-bold text-brand-black100 my-[10px];
}
.seo-text ol {
  @apply list-disc ml-[45px]
}
.seo-hidden-link {
  @apply w-[1px] h-[1px] !absolute overflow-hidden m-[-1px] p-0 border-0;
}

.vto-mobile-scroll-overlay::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important
}
    
.vto-mobile-scroll-overlay::-webkit-scrollbar * {
  background: transparent;
}
    
.vto-mobile-scroll-overlay::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.1) !important;
}

.st-2-column {
  @apply grid grid-cols-1 items-center md:grid-cols-[50%,auto] gap-[20px] md:gap-[120px] md:my-[40px] my-[20px];
}

.st-comment-block {
  @apply border-l-[4px] p-[32px] flex items-center border-brand-orange bg-brand-grey100 bg-opacity-60 text-[14px] font-medium
}

.st-blog-p-title{
  @apply font-medium text-[26px] my-[20px]
}
.st-blog-main-image {
  @apply w-full;
}

[data-dresden-dropzone] * {
  pointer-events: none;
}

.dresden-select {
	background: white url("data:image/svg+xml,%3Csvg width='15' height='9' viewBox='0 0 15 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.07107 8.6267L7.0703 8.62746L5.51467 7.07183L5.51543 7.07106L0 1.55563L1.55564 0L7.07107 5.51542L12.5857 0.000749674L14.1414 1.55639L8.62671 7.07106L7.07107 8.6267Z' fill='%2300AEC7'/%3E%3C/svg%3E") no-repeat;
  background-position: right 0px top 50%;
}

.custom-number-input::-webkit-outer-spin-button,
.custom-number-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

[data-dresden-phone-input-wrapper] input {
  line-height: inherit;
  font-size: inherit;
  @apply outline-none border-none bg-transparent pl-0 w-full h-auto tracking-normal;
}


/* The container */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #2A2A2A;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #CACACA;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: #FF8200;
  border: 1px solid #FF8200;

}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 5px;
  top: 1px;
  width: 7px;
  height: 11px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
}

.rounded-checkmark {
	position: absolute;
  top: 2px;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #CACACA;
}

.checkbox-container input:checked ~ .rounded-checkmark {
  background-color: #FF8200;
  border: 1px solid #FF8200;
}

.rounded-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-container input:checked ~ .rounded-checkmark:after {
  display: block;
}

.checkbox-container .rounded-checkmark:after {
  left: 5.5px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
}

/* Create a custom checkbox */
.radio-checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #fff;
  border-radius: 18px;
  border: 1px solid #CACACA;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .radio-checkmark {
}

/* Create the checkmark/indicator (hidden when not checked) */
.radio-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .radio-checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .radio-checkmark:after {
  left: 2px;
  top: 2px;
  width: 12px;
  height: 12px;
  border-radius: 12px;
  border: solid white;
  background-color: #FFA800;
}

.htmlContent a{
  color: #00AEC7;
}

.dresden-checkbox{
  appearance: none;
}
.dresden-checkbox::before{
  position: absolute;
  width: 18px;
  height: 18px;
  background-color: white;
  border-radius: 4px;
  content:' ';
  border:1px solid #CACACA;
}

.dresden-checkbox:checked::after{
  position: absolute;
  content: url("data:image/svg+xml,%0A%3Csvg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.32134 6.87788L2.32115 6.87807L3.6564 8.21333L3.6566 8.21314L3.65717 8.21371L4.99242 6.87845L4.99185 6.87788L10.3348 1.53496L8.99951 0.199707L3.6566 5.54262L1.45689 3.34292L0.121632 4.67817L2.32134 6.87788Z' fill='white'/%3E%3C/svg%3E%0A");
  text-align: center;
  line-height: 15px;
  color: white;
  width: 18px;
  height: 18px;
  border-radius: 4px;
  background-color: #ff8200;
}

.dresden-checkbox:hover::before{
  box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.25) inset;
-webkit-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.25) inset;
-moz-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.25) inset;
}


.dresden-radio{
  appearance: none;
}
.dresden-radio::before{
  position: absolute;
  width: 18px;
  height: 18px;
  background-color: white;
  border-radius: 50%;
  content:' ';
  border:1px solid #CACACA;
}

.dresden-radio:checked::before{
  border:1px solid #ff8200;
}

.dresden-radio:checked::after{
  position: absolute;
  content: '';
  text-align: center;
  line-height: 15px;
  width: 10px;
  height: 10px;
  top:4px;
  left:4px;
  border-radius: 50%;
  background-color: #ff8200;
  z-index: 22;
}

.blog-image img{
  margin: 0 !important;
  width: 100% !important;
  height: 75% !important;
  min-width: 100%;
  max-width: 100%;
  min-height: 75% !important;
  max-height: 100% !important;
  object-fit: cover;
}

.before-selector-override::before {
  left: 95% !important;
}

.after-selector-override::after {
  left: 95% !important;
}

/* BLOG PAGE CUSTOM VIDEO CONTROL */

.video-control-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%,-50%,0);
  padding: 0;
  border-radius: 0;
  border-color: #6d71750d;
  color: #6d7175;
  display: flex;
  background: white;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
}

/* .video-wrapper {
  width: 50%;
  padding: 5%;
  position: relative;
} */

/* @media only screen and (max-width: 640px) {
  .video-wrapper {
    width: 65%;
  }
} */

.deneme-1 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 70%;
}

.play-button {
  display: contents;
}

.deneme-3 {
  display: flex;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.deneme-2 {
  display: flex;
  justify-content: center;
  position: relative;
}

.video-deneme {
  width: 70%;
  visibility: hidden;
}

.video-poster {
  width: 70%;
  position: absolute;
}

.video-poster-horizontal {
	width: 80%;
	position: absolute;
}

.video-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-deneme-horizontal {
	width: 80%;
	visibility: hidden;
}

@media only screen and (max-width: 768px) {
  .video-control-button {
    width: 30px;
    height: 30px;
  }

  .deneme-3 {
    gap: 2rem;
    flex-direction: column;
  }

  .deneme-1 {
    width: 100%;
  }

  .video-deneme {
    width: 50%;
  }

  .video-poster {
    width: 50%;
  }

  .career-button-text{
    font-size: 16px;
  }
}

@media only screen and (max-width: 1024px) {
  .video-control-button {
    width: 40px;
    height: 40px;
  }
	.swiper-pagination {
		display: block;
	}
}

/* .video-poster-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
} */
#faceBackground::before {
  content: " " !important;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc((100% - 885px) / 2) ;
  background: #000;
  opacity: 0.5;
}

#faceBackground::after {
  content: " " !important;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc((100% - 716px) / 2);
  background: #000;
  opacity: 0.5;
  z-index: 1;
}


/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

}  
.hide-scroll::-webkit-scrollbar {
  display: none;
}

.hide-scroll {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.white-tip::after {
	border-left: 8px solid transparent !important;
	border-right: 8px solid transparent !important;
	bottom: -6px !important;
	left: 50% !important;
	margin-left: -8px !important;
	border-top-color: white !important;
	border-top-style: solid !important;
	border-top-width: 6px !important;
  background-color:white !important;
  border: 1px solid #E2E2E2 !important;
}

.h-116px {
	height: 116px;
}

.hmtl_container p{
  margin: 0;
}

.html_container ol{
  list-style: inside;
}

.html_container ul{
  list-style: inside;
}

.custom-stripe-container .StripeElement {
	padding: 0px;
}

.text-shadow-stroke {
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #000;
}
.faqContent {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
  opacity: 0;
}

.faqContent.open {
  max-height: 1000px; /* adjust according to your needs */
  opacity: 1;
}

@media (max-width: 1023px) {
  .swiper-nav-button {
    display: none !important;
  }
}

@media (min-width: 1024px) {
  .swiper-nav-button {
    display: flex !important;
  }
}

.swiper-nav-button {
  display: none;
}


.acc-height {
  min-height: 275px;
  height: 100%;
}
@media (min-width: 1024px) {
  .acc-height {
    min-height: 420px;
  }
}

.product-acc-height{
  height: 560px;
}
@media (max-width: 1023px) {
  .product-acc-height{
    height: 420px;
  }
}